import React, { useState, useEffect } from "react";
import "./Gallery.scss";

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const openImage = (src) => {
    setSelectedImage(src);
  };
  const closeImage = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const imagesArt = [
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_05DehumanizationSeries.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_05DehumanizationSeries",
    },
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_04DehumanizationSeries.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_04DehumanizationSeries",
    },
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_17DehumanizationSeries.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_17DehumanizationSeries",
    },
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_07DehumanizationSeries.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_07DehumanizationSeries",
    },
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_Tecnología_Entre lo humano y lo digital_03.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_Tecnología_Entre lo humano y lo digital_03",
    },
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_10DehumanizationSeries.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_10DehumanizationSeries",
    },
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_01DehumanizationSeries.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_01DehumanizationSeries",
    },
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_18DehumanizationSeries.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_18DehumanizationSeries",
    },
    { src: "./obras/mauro-testa_fotografiademoda__fotografo_heart.jpg?v=1", title: "mauro-testa_fotografiademoda__fotografo_heart" },
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_14DehumanizationSeries.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_14DehumanizationSeries",
    },
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_nakano2.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_nakano2",
    },
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_09DehumanizationSeries.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_09DehumanizationSeries",
    },
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_16DehumanizationSeries.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_16DehumanizationSeries",
    },
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_02DehumanizationSeries.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_02DehumanizationSeries",
    },
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_08DehumanizationSeries.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_08DehumanizationSeries",
    },
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_20DehumanizationSeries.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_20DehumanizationSeries",
    },
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_nakano1.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_nakano1",
    },
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_13DehumanizationSeries.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_13DehumanizationSeries",
    },
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_19DehumanizationSeries.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_19DehumanizationSeries",
    },
    {
      src: "./obras/mauro-testa_fotografiademoda__fotografo_15DehumanizationSeries.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_15DehumanizationSeries",
    },

  ];
  const imagesFashion = [
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_ella5.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_ella5",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_td1.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_td1",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_ella2.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_ella2",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_lb.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_lb",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_ella4.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_ella4",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_lt3.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_lt3",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_lt5.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_lt5",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_marina2.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_marina2",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_td3.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_td3",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_lt4.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_lt4",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_ella.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_ella",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_ella6.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_ella6",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_mo03.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_mo03",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_lt.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_lt",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_be1.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_be1",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_ella3.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_ella3",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_vp3.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_vp3",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_mp2.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_mp2",
    },
    { src: "./fashion/mauro-testa_fotografiademoda__fotografo_vp4.jpg?v=1", title: "mauro-testa_fotografiademoda__fotografo_vp4" },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_td2.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_td2",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_m02.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_m02",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_bbyn.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_bbyn",
    },
    {
      src: "./fashion/mauro-testa_fotografiademoda__fotografo_mo5.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_mo5",
    },
  ];

  const imagesCovers = [
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_20.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_20",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_17.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_17",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_18.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_18",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_25.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_25",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_28.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_28",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_15.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_15",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_02.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_02",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_07.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_07",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_21.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_21",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_19.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_19",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_24.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_24",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_26.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_26",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_01.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_01",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_03.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_03",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_04.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_04",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_09.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_09",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_10.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_10",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_12.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_12",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_14.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_14",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_13.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_13",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_22.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_22",
    },
    {
      src: "./covers/mauro-testa_fotografiademoda__fotografo_cover_27.jpg?v=1",
      title: "mauro-testa_fotografiademoda__fotografo_cover_27",
    },
  ];
  const imagesPortrait = [
    {
      src: "./portraits/mauro-testa_portrait__fotografo_aleja3.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_aleja3",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_sara.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_sara",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_gogo7.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_gogo7",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_virgin.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_virgin",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_ant.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_ant",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_painter.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_painter",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_pasc2.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_pasc2",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_clown.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_clown",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_gogo4.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_gogo4",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_iñigo2.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_iñigo2",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_aleja.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_aleja",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_chipe2.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_chipe2",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_lucia.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_lucia",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_ant2.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_ant2",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_lara.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_lara",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_gogo6.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_gogo6",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_madmax.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_madmax",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_sasha.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_sasha",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_raver.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_raver",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_angel.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_angel",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_greentea.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_greentea",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_pasc.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_pasc",
    },
    {
      src: "./portraits/mauro-testa_portrait__fotografo_aleja2.jpg?v=1",
      title: "mauro-testa_portrait__fotografo_aleja2",
    },
  ];
  const imagesBbc = [
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo6.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo6",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo13.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo13",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_co4.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_co4",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_ba.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_ba",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo5.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo5",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo10.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo10",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_co.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_co",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo15.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo15",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo8.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo8",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_co10.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_co10",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo12.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo12",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo3.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo3",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_co3.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_co3",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo14.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo14",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo2.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo2",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_ba2.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_ba2",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_ba3.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_ba3",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo11.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_bo11",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_co7.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_co7",
    },
    {
      src: "./bbc/maurotesta_fotografiadebodasbautizoscomuniones__fotografo_co8.jpg?v=1",
      title: "maurotesta_fotografiadebodasbautizoscomuniones__fotografo_co8",
    },
  ];

  return (
    <div className="gallery">
      {window.location.pathname.includes("/art") &&
        imagesArt.map((image, index) => (
          <div className="gallery__cont" key={index}>
            <img
              className="gallery__cont--img"
              src={image.src}
              alt={image.title}
              loading="lazy"
              onClick={() => openImage(image.src)}
            />
          </div>
        ))}
      {window.location.pathname.includes("/fashion") &&
        imagesFashion.map((image, index) => (
          <div className="gallery__cont" key={index}>
            <img
              className="gallery__cont--img"
              src={image.src}
              alt={image.title}
              loading="lazy"
              onClick={() => openImage(image.src)}
            />
          </div>
        ))}
      {window.location.pathname.includes("/portrait") &&
        imagesPortrait.map((image, index) => (
          <div className="gallery__cont" key={index}>
            <img
              className="gallery__cont--img"
              src={image.src}
              alt={image.title}
              loading="lazy"
              onClick={() => openImage(image.src)}
            />
          </div>
        ))}
      {window.location.pathname.includes("/music") &&
        imagesCovers.map((image, index) => (
          <div className="covers" key={index}>
            <img
              className="coversInd"
              src={image.src}
              alt={image.title}
              loading="lazy"
              onClick={() => openImage(image.src)}
            />
            <p class="texto">Available</p>
          </div>
        ))}
      {window.location.pathname.includes("/bodasbautizosycomuniones") &&
        imagesBbc.map((image, index) => (
          <div className="gallery__cont" key={index}>
            <img
              className="gallery__cont--img"
              src={image.src}
              alt={image.title}
              loading="lazy"
              onClick={() => openImage(image.src)}
            />
            <p class="texto">Available</p>
          </div>
        ))}
      {selectedImage && windowWidth >= 1000 && (
        <div className="lightbox">
          <div className="lightbox__overlay" onClick={closeImage}></div>
          <div className="lightbox__content">
            <img src={selectedImage} alt="Large Image" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
