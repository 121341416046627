import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <nav className="navFooter">
        <div className="redes">
          <a
            className="redesInd"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/_maurotesta/?hl=es"
          >
            INSTAGRAM
          </a>
          <a
            className="redesInd"
            target="_blank"
            rel="noopener noreferrer"
            href="https://pin.it/3l01ckf"
          >
            PINTEREST
          </a>
          <a
            className="redesInd"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/mauro-testa/"
          >
            LINKEDIN
          </a>
          <a
            className="redesInd"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/channel/UCrfNxca-IN4mdYYLyKw6qXA"
          >
            YOUTUBE
          </a>
        </div>
        {/* <div className="ac">
        {window.location.pathname.startsWith('/contact') ? <Link style={{color: "red"}} className="linksInd" to={"/contact"}>CONTACT</Link> : <Link className="linksInd" to={"/contact"}>CONTACT</Link>}
        </div> */}
      </nav>
    </footer>
  );
};

export default Footer;
