import React, { useState } from "react";
import "./NavBar.scss";
import Hamburguer from "../Hamburguer/Hamburguer";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [clicked, setClicked] = useState(false);
  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <nav className="navBar">
      <Link to="/">
        <h1 className="navBar__title">MAURO TESTA</h1>
      </Link>

      <div className={`links ${clicked ? "active" : ""}`}>
        {window.location.pathname.startsWith("/art") ? (
          <Link to="/art" style={{ color: "red" }} className="links--red">
            ART
          </Link>
        ) : (
          <Link to="/art" className="links--a">
            ART
          </Link>
        )}
        {window.location.pathname.startsWith("/fashion") ? (
          <Link to="/fashion" style={{ color: "red" }} className="links--a">
            FASHION
          </Link>
        ) : (
          <Link to="/fashion" className="links--a">
            FASHION
          </Link>
        )}
        {window.location.pathname.startsWith("/portrait") ? (
          <Link to="/portrait" style={{ color: "red" }} className="links--a">
          PORTRAIT
          </Link>
        ) : (
          <Link to="/portrait" className="links--a">
            PORTRAIT
          </Link>
        )}
        {window.location.pathname.startsWith("/contact") ? (
          <Link style={{ color: "red" }} className="links--a" to={"/contact"}>
            CONTACT
          </Link>
        ) : (
          <Link className="links--a" to={"/contact"}>
            CONTACT
          </Link>
        )}
      </div>
      <div className="hamburguer">
        <Hamburguer clicked={clicked} handleClick={handleClick}></Hamburguer>
      </div>
      <div className={`initial ${clicked ? " active" : ""}`}></div>
    </nav>
  );
};

export default NavBar;
