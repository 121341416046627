import React from "react";
import "./ContactPage.scss";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";

const ContactPage = () => {
  return (
    <>
      <NavBar></NavBar>
      <div className="conPadre">
        <div className="conHijo">
          <h2 className="conH2">EMAIL</h2>
          <a href="mailto:maurocandas@gmail.com">
            <h1 className="conH1">maurocandas@gmail.com</h1>
          </a>
        </div>
        <div className="conHijo">
          <h2 className="conH2">PHONE</h2>
          <a href="tel:+34663480566">
            <h1 className="conH1">663480566</h1>
          </a>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default ContactPage;
