import React, { useEffect, useState } from 'react'
import "./ScrollButton.scss"

const ScrollButton = () => {
    const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Función para mostrar u ocultar el botón dependiendo del desplazamiento vertical de la página.
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Agregar un evento de escucha para el desplazamiento de la ventana.
    window.addEventListener('scroll', handleScroll);

    // Eliminar el evento de escucha cuando el componente se desmonte.
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Función para desplazarse al principio de la página.
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Desplazamiento suave
    });
  };
  return (
    isVisible && (
        <button className={`scrollButton ${isVisible ? '' : 'hidden'}`} onClick={scrollToTop}>
        <img className='scrollButton__img' src='./MT_boton.png'></img>
        </button>
      )
  )
}

export default ScrollButton