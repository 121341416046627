import React from "react";
import Gallery from "../../components/Gallery/Gallery";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import ScrollButton from "../../components/ScrollButton/ScrollButton";

const ArtPage = () => {
  return (
    <>
      <NavBar></NavBar>
      <Gallery></Gallery>
      <ScrollButton></ScrollButton>
      <Footer></Footer>
    </>
  );
};

export default ArtPage;
