import React, { useEffect } from 'react'
import NavBar from '../../components/NavBar/NavBar'
import Gallery from '../../components/Gallery/Gallery'
import Footer from '../../components/Footer/Footer'
import { useNavigate } from 'react-router-dom'

const PortraitPage = () => {
    const navigate = useNavigate();
  useEffect(() => {
    navigate("/portrait");
  }, [navigate]);
  return (
    <>
      <NavBar></NavBar>
      <Gallery></Gallery>
      <Footer></Footer>
    </>
  )
}

export default PortraitPage