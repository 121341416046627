import React, { useState, useEffect } from "react";
import "./HomePage.scss";
import { Link } from "react-router-dom";

const HomePage = () => {
  const [showSecondImage, setShowSecondImage] = useState(false);

  useEffect(() => {
    // Después de 10 segundos, establece showSecondImage en true
    const timer = setTimeout(() => {
      setShowSecondImage(true);
    }, 10000);

    // Limpia el temporizador cuando el componente se desmonta
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`fondo ${showSecondImage ? "showSecondImage" : ""}`}>
      <Link className="homeLink" to={"/fashion"}>
        <img src="./titulo.png" alt="titulo" className="tituloPortada"></img>
      </Link>
      <div className="galleryContainer">
        {/* Aquí puedes agregar tu galería de imágenes */}
        <img
          src="./obras/mauro-testa_fotografiademoda__fotografo_05DehumanizationSeries.jpg?v=1"
          alt="mauro-testa_fotografiademoda__fotografo_05DehumanizationSeries"
          className="galleryImage"
        />
        <img
          src="./fashion/mauro-testa_fotografiademoda__fotografo_td1.jpg?v=1"
          alt="./fashion/mauro-testa_fotografiademoda__fotografo_td1"
          className="galleryImage"
        />
        <img
          src="./obras/mauro-testa_fotografiademoda__fotografo_07DehumanizationSeries.jpg?v=1"
          alt="./obras/mauro-testa_fotografiademoda__fotografo_07DehumanizationSeries"
          className="galleryImage"
        />
        <img
          src="./obras/mauro-testa_fotografiademoda__fotografo_04DehumanizationSeries.jpg?v=1"
          alt="./obras/mauro-testa_fotografiademoda__fotografo_04DehumanizationSeries"
          className="galleryImage"
        />
        <img
          src="./obras/mauro-testa_fotografiademoda__fotografo_17DehumanizationSeries.jpg?v=1"
          alt="./obras/mauro-testa_fotografiademoda__fotografo_17DehumanizationSeries"
          className="galleryImage"
        />
        <img
          src="./obras/mauro-testa_fotografiademoda__fotografo_Tecnología_Entre lo humano y lo digital_03.jpg?v=1"
          alt="./obras/mauro-testa_fotografiademoda__fotografo_Tecnología_Entre lo humano y lo digital_03"
          className="galleryImage"
        />
        <img
          src="./fashion/mauro-testa_fotografiademoda__fotografo_ella5.jpg?v=1"
          alt="./fashion/mauro-testa_fotografiademoda__fotografo_ella5"
          className="galleryImage"
        />
        <img
          src="./fashion/mauro-testa_fotografiademoda__fotografo_ella2.jpg?v=1"
          alt="./fashion/mauro-testa_fotografiademoda__fotografo_ella2"
          className="galleryImage"
        />
        <img
          src="./fashion/mauro-testa_fotografiademoda__fotografo_lb.jpg?v=1"
          alt="./fashion/mauro-testa_fotografiademoda__fotografo_lb"
          className="galleryImage"
        />
        <img
          src="./fashion/mauro-testa_fotografiademoda__fotografo_ella4.jpg?v=1"
          alt="./fashion/mauro-testa_fotografiademoda__fotografo_ella4"
          className="galleryImage"
        />
        {/* Agrega más imágenes según sea necesario */}
      </div>
      {/* Palabras clave ocultas */}
      <h1 style={{ position: "absolute", top: "-9999px", left: "-9999px" }}>
        Fotografo de moda, fotografo, artista, fashion photographer, artist,
        madrid, Mauro Testa
        {/* Agrega más palabras clave ocultas según sea necesario */}
      </h1>
    </div>
  );
};

export default HomePage;

{
  /* <img
        src="./busto.png"
        alt="segunda_imagen"
        className={`segundaImagen ${
          showSecondImage ? "showSecondImage" : ""
        }`}
      ></img> */
}
