import "./App.scss";
import ArtPage from "./pages/ArtPage/ArtPage";
import { Route, BrowserRouter as Router, Routes, useNavigate } from "react-router-dom";
import ContactPage from "./pages/ContactPage/ContactPage";
import HomePage from "./pages/HomePage/HomePage";
import MenuPage from "./pages/MenuPage/MenuPage";
import NewCoversPage from "./pages/NewCoversPage/NewCoversPage";
import ModaPage from "./pages/ModaPage/ModaPage";
import VideoPage from "./pages/VideoPage/VideoPage";
import BbcPage from "./pages/BbcPage/BbcPage";
import PortraitPage from "./pages/PortraitPage/PortraitPage";
import { useEffect } from "react";

function App() {
  return (
    <Router>
      <div className="divPadre">
        <Routes>
          <Route path="/" element={<HomePage></HomePage>}></Route>
          <Route path="/art" element={<ArtPage></ArtPage>}></Route>
          <Route path="/contact" element={<ContactPage></ContactPage>}></Route>
          {/* <Route path="/menu" element={<MenuPage></MenuPage>}></Route> */}
          {/* <Route path="/video" element={<VideoPage></VideoPage>}></Route> */}
          <Route
            path="/music"
            element={<NewCoversPage></NewCoversPage>}
          ></Route>
          <Route path="/fashion" element={<ModaPage></ModaPage>}></Route>
          <Route path="/bodasbautizosycomuniones" element={<BbcPage></BbcPage>}></Route>
          <Route path="/portrait" element={<PortraitPage></PortraitPage>}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
