import React, { useEffect } from "react";
import "./BbcPage.scss";
import NavBar from "../../components/NavBar/NavBar";
import Gallery from "../../components/Gallery/Gallery";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";

const BbcPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/bodasbautizosycomuniones");
  }, [navigate]);
  return (
    <>
      <NavBar></NavBar>
      <Gallery></Gallery>
      <Footer></Footer>
    </>
  );
};

export default BbcPage;
